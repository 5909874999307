<!-- =========================================================================================
	File Name: TableMiscellaneous.vue
	Description: Combine filter,sorter, pagination etc.
	----------------------------------------------------------------------------------------
	Item Name: Vuesax Admin - VueJS Dashboard Admin Template
	Version: 1.1
	Author: Pixinvent
	Author URL: hhttp://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <vx-card>
    <!-- HEADER -->

    <div
      id="facility-card"
      class="grid-layout-container alignment-block"
      style="font-family: SukhumvitSet-Medium; vertical-align:text-top ;width:100%; padding: 30px ; margin-bottom: 20px;"
    >
      <vs-row vs-w="12">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-lg="3"
          vs-sm="3"
          vs-xs="3"
        >
          <!-- ชื่อผู้เช่า -->

          <vs-row vs-w="12" style="margin-top:30px;">
            <vs-col
              class="theLabel"
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="center"
              vs-lg="5"
              vs-sm="5"
              vs-xs="5"
            >ชื่อผู้เช่า</vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-lg="6"
              vs-sm="6"
              vs-xs="6"
            >{{this.customerName}}</vs-col>
          </vs-row>
          <!-- เบอร์ติดต่อ -->
          <vs-row vs-w="12" style="margin-top:5px;">
            <vs-col
              class="theLabel"
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="center"
              vs-lg="5"
              vs-sm="5"
              vs-xs="5"
            >เบอร์ติดต่อ</vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-lg="6"
              vs-sm="6"
              vs-xs="6"
            >{{this.customerPhone}}</vs-col>
          </vs-row>
          <!-- ที่อยู่ -->
          <vs-row vs-w="12" style="margin-top:5px;">
            <vs-col
              class="theLabel"
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="center"
              vs-lg="5"
              vs-sm="5"
              vs-xs="5"
            >ที่อยู่</vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-lg="6"
              vs-sm="6"
              vs-xs="6"
            >{{this.customerAddress}}</vs-col>
          </vs-row>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="flex-start"
          vs-lg="5"
          vs-sm="5"
          vs-xs="5"
        >
          <vs-row vs-w="12" vs-type="flex" vs-align="flex-start">
            <!-- ใบเสร็จรับเงิน -->
            <vs-row vs-w="12" vs-type="flex" vs-align="flex-start">
              <vs-col
                class="theLabel"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-lg="12"
                vs-sm="12"
                vs-xs="12"
              ></vs-col>
            </vs-row>
            <!-- เบอร์ติดต่อ -->
            <vs-row vs-w="12" style="margin-top: 5px;">
              <vs-col
                class="theLabel"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-lg="12"
                vs-sm="12"
                vs-xs="12"
              >
                เลข Order &nbsp;
                <span style="font-weight: light;">{{this.orderNumber}}</span>
              </vs-col>
            </vs-row>
            <!-- วันที่ออก -->
          </vs-row>
          <!--  ปุ่ม -->
          <vs-row vs-w="12" vs-type="flex" vs-justify="center">
            <vs-button
              @click="savePrepared(noworderid)"
              color="success"
              type="filled"
            >บันทึกจัดชุดเรียบร้อยแล้ว</vs-button>
          </vs-row>
          <!--------------------------------------------------------------------->
        </vs-col>

        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="flex-start"
          vs-lg="4"
          vs-sm="4"
          vs-xs="4"
        >
          <!-- ปุ่ม PRINT -->
          <vs-row vs-w="12" style="margin-top:30px;">
            <vs-button
              vs-type="flex"
              vs-align="center"
              vs-justify="center"
              style="width:100%; padding-left:0; padding-right:0;"
              color="rgb(255, 114, 131)"
              type="filled"
              :href="getRedirectLink(this.orderHeaderId)"
              target="blank"
            >
              <span>
                <i
                  style="font-size:18px; margin-right:5px;vertical-align:middle;"
                  class="material-icons"
                >print</i>
              </span>
              <span style="vertical-align:middle;">พิมพ์ใบจัดชุด</span>
            </vs-button>
          </vs-row>
          <!-- กำหนดรับ -->
          <vs-row vs-w="12" style="margin-top:30px;">
            <vs-col
              class="theLabel"
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="center"
              vs-lg="5"
              vs-sm="5"
              vs-xs="5"
            >กำหนดรับ</vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-lg="6"
              vs-sm="6"
              vs-xs="6"
            >{{formatDate(this.startDate)}}</vs-col>
          </vs-row>
          <!-- กำหนดคืน -->
          <vs-row vs-w="12" style="margin-top:5px;">
            <vs-col
              class="theLabel"
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="center"
              vs-lg="5"
              vs-sm="5"
              vs-xs="5"
            >กำหนดคืน</vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-lg="6"
              vs-sm="6"
              vs-xs="6"
            >{{formatDate(this.endDate)}}</vs-col>
          </vs-row>
          <vs-row
            style="margin-top: 20px; text-align:right;  font-weight: bold;"
            vs-w="12"
            vs-type="flex"
            vs-align="flex-end"
            vs-justify="flex-end"
          >{{shopinfo.name}} สาขา {{ aBranch.branchName }}</vs-row>
        </vs-col>
      </vs-row>
    </div>

    <!-- ตาราง -->
    <vs-table hoverFlat stripe :data="orders">
      <template slot="thead">
        <vs-th>รูปตัวอย่าง</vs-th>
        <vs-th style="width:200px;" sort-key="itemName">ชื่อสินค้า</vs-th>
        <vs-th sort-key="reservedQuantity">จำนวน</vs-th>
        <vs-th style="text-align:center;" sort-key="rentalPrice">ราคาเช่า</vs-th>
        <vs-th style="text-align:center;" sort-key="bail">เงินประกัน</vs-th>
        <vs-th style="text-align:center;">แอดมินเช็ค</vs-th>
        <vs-th style="text-align:center;">ลูกค้าเช็ค</vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr class="table-font" :data="tr" :key="indextr" v-for="(tr, indextr) in orders">
          <vs-td :data="data[indextr].mainImage">
            <img
              class="responsive rounded"
              :src="data[indextr].mainImage"
              alt="user-upload"
              style="width:70px;"
            />
          </vs-td>

          <vs-td :data="data[indextr].name">
            {{data[indextr].name}}
            <br />

            <span style="font-size:9px;">({{data[indextr].code}})</span>
            <br />
            <i
              v-if="getRemoved(indextr) === 'y'"
              class="vs-icon notranslate icon-scale vs-button--icon material-icons null"
              style="order: 0;text-align:left;  font-size:25px; color:red; cursor:pointer;"
            >cancel</i>
          </vs-td>

          <vs-td :data="data[indextr].reservedQuantity">{{data[indextr].reservedQuantity}}</vs-td>

          <vs-td :data="data[indextr].rentalPrice">
            <span
              style="padding:14px 10px;"
            >{{formatPrice(data[indextr].rentalPrice * data[indextr].reservedQuantity)}}</span>
          </vs-td>

          <vs-td :data="data[indextr].bail">
            <span
              style="padding:14px 10px;"
            >{{formatPrice(data[indextr].bail * data[indextr].reservedQuantity)}}</span>
          </vs-td>
          <vs-td>
            <div class="notChoose">เช็คของ</div>
          </vs-td>
          <vs-td>
            <div class="notChoose">เช็คของ</div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <!-- BEGIN: MONETARY SUMMATION -->
    <div class="grid-layout-container alignment-block" style="width:100%; margin-top: 20px;">
      <vs-row vs-w="12">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="flex-start"
          vs-lg="7"
          vs-sm="7"
          vs-xs="7"
        >
          <vs-row style="margin-top:10px;" vs-w="12">
            <i
              style="font-size:20px; margin-left:5px; margin-right:5px;vertical-align:middle;  border-radius:10px; "
              class="material-icons iconhover"
            >local_shipping</i>
            วิธีรับชุด : {{receivingMethod}}
            <br />
            <br />
            หมายเหตุ :
            {{remark1}}
          </vs-row>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="5" vs-sm="5" vs-xs="5">
          <div class="grid-layout-container alignment-block" style="width:100%; margin-top: 20px;">
            <!-- รวมค่าเช่า -->
            <vs-row vs-w="12" style="padding: 10px 20px; " class="grand-total the-black">
              <!-- GRAND TOTAL MONETARY SUMMATION -->

              <vs-col
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="center"
                vs-lg="6"
                vs-sm="6"
                vs-xs="6"
              >ยอดรวมค่าเช่าชุด</vs-col>
              <vs-col
                style="padding-left: 30px;  "
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-lg="6"
                vs-sm="6"
                vs-xs="6"
              >{{formatPrice(rentalPriceTotal)}} บาท</vs-col>
            </vs-row>
            <!-- ส่วนลดค่าเช่า -->
            <vs-row
              v-if="rentalDiscount > 0 && rentalDiscount != undefined && rentalDiscount!= null"
              vs-w="12"
              style="padding: 0 20px; "
              class="grand-total-discount the-red"
            >
              <vs-col
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="center"
                vs-lg="6"
                vs-sm="6"
                vs-xs="6"
                style="  "
              >ส่วนลดค่าเช่า</vs-col>
              <vs-col
                style="padding-left: 30px;  "
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-lg="6"
                vs-sm="6"
                vs-xs="6"
              >{{formatPrice(rentalDiscount)}} บาท</vs-col>
            </vs-row>
            <!-- ขีดเส้นใต้ -->
            <vs-row vs-w="12" style="height: 10px !important;">
              <vs-col
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="center"
                vs-lg="6"
                vs-sm="6"
                vs-xs="6"
                style="border-bottom: 1px solid #f4f4f4; "
              >&nbsp;</vs-col>
              <vs-col
                style="padding-left: 30px; border-bottom: 1px solid #f4f4f4; "
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-lg="6"
                vs-sm="6"
                vs-xs="6"
              >&nbsp;</vs-col>
            </vs-row>
            <!-- รวมค่าประกัน -->
            <vs-row vs-w="12" style="padding: 10px 20px; " class="grand-total the-black">
              <vs-col
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="center"
                vs-lg="6"
                vs-sm="6"
                vs-xs="6"
                style=" padding-top: 20px; "
              >ยอดรวมค่าประกัน</vs-col>
              <vs-col
                style="padding-left: 30px;  padding-top: 20px; "
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-lg="6"
                vs-sm="6"
                vs-xs="6"
              >{{formatPrice(bailTotal)}} บาท</vs-col>
            </vs-row>
            <!-- ส่วนลดค่าประกัน -->
            <vs-row
              v-if="bailDiscount > 0 && bailDiscount != undefined && bailDiscount!= null"
              vs-w="12"
              style="padding: 0 20px; "
              class="grand-total-discount the-red"
            >
              <vs-col
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="center"
                vs-lg="6"
                vs-sm="6"
                vs-xs="6"
                style="  "
              >ส่วนลดค่าประกัน</vs-col>
              <vs-col
                style="padding-left: 30px;  "
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-lg="6"
                vs-sm="6"
                vs-xs="6"
              >{{formatPrice(bailDiscount)}} บาท</vs-col>
            </vs-row>
            <!-- ขีดเส้นใต้ -->
            <vs-row vs-w="12" style="height: 10px !important;">
              <vs-col
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="center"
                vs-lg="6"
                vs-sm="6"
                vs-xs="6"
                style="border-bottom: 1px solid #f4f4f4; "
              >&nbsp;</vs-col>
              <vs-col
                style="padding-left: 30px; border-bottom: 1px solid #f4f4f4; "
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-lg="6"
                vs-sm="6"
                vs-xs="6"
              >&nbsp;</vs-col>
            </vs-row>
            <!-- ส่วนลดอื่นๆ -->
            <vs-row
              v-if="promotionDiscount > 0 && promotionDiscount != undefined && promotionDiscount!= null"
              vs-w="12"
              style="padding: 0 20px;margin-top:15px;"
              class="grand-total-discount the-red"
            >
              <vs-col
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="center"
                vs-lg="6"
                vs-sm="6"
                vs-xs="6"
                style="padding-top: 20px;  "
              >ส่วนลดอื่นๆ</vs-col>
              <vs-col
                style="padding-left: 30px;  padding-top: 20px; "
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-lg="6"
                vs-sm="6"
                vs-xs="6"
              >{{formatPrice(promotionDiscount)}} บาท</vs-col>
            </vs-row>
            <!-- ขีดเส้นใต้ -->
            <vs-row vs-w="12" style="height: 10px !important;">
              <vs-col
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="center"
                vs-lg="6"
                vs-sm="6"
                vs-xs="6"
                style="border-bottom: 1px solid #f4f4f4; padding:0;height:15px;"
              >&nbsp;</vs-col>
              <vs-col
                style="padding-left: 30px; border-bottom: 1px solid #f4f4f4; padding:0;height:15px;"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-lg="6"
                vs-sm="6"
                vs-xs="6"
              >&nbsp;</vs-col>
            </vs-row>
            <!-- ยอดรวมทั้งหมด -->
            <vs-row
              vs-w="12"
              style="padding: 0 20px; margin-top:30px; "
              class="grand-total the-blue"
            >
              <vs-col
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="center"
                vs-lg="6"
                vs-sm="6"
                vs-xs="6"
              >ยอดรวมทั้งหมด</vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-lg="6"
                vs-sm="6"
                vs-xs="6"
                style="padding-left:30px;"
              >{{formatPrice(grandTotal)}} บาท</vs-col>
            </vs-row>

            <!-- ขีดเส้นใต้ -->
            <vs-row vs-w="12" style="height: 10px !important;">
              <vs-col
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="center"
                vs-lg="6"
                vs-sm="6"
                vs-xs="6"
                style="border-bottom: 4px solid #f0f0f0; padding:0;height:20px;"
              >&nbsp;</vs-col>
              <vs-col
                style="border-bottom: 4px solid #f0f0f0;  padding-left: 30px;  padding:0;height:20px;"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-lg="6"
                vs-sm="6"
                vs-xs="6"
              >&nbsp;</vs-col>
            </vs-row>
            <!-- เว้นช่องไฟ -->
            <vs-row vs-w="12" style="margin-top:20px; height: 20px !important;">
              <vs-col
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="center"
                vs-lg="6"
                vs-sm="6"
                vs-xs="6"
                style=" padding:0;height:15px;"
              >&nbsp;</vs-col>
              <vs-col
                style="padding-left: 30px;  padding:0;height:15px;"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-lg="6"
                vs-sm="6"
                vs-xs="6"
              >&nbsp;</vs-col>
            </vs-row>
          </div>
        </vs-col>
      </vs-row>
    </div>
    <!-- END: MONETARY SUMMATION -->

    <!--------------------------------------------------------------------->
    <!--------------------------------------------------------------------->
    <div style="border-top: 1px solid #E3E3E3; margin-bottom:40px; "></div>
    <!--------------------------------------------------------------------->

    <!--  ปุ่ม -->
    <vs-row vs-w="12" vs-type="flex" vs-justify="center">
      <vs-button
        @click="savePrepared(noworderid)"
        color="success"
        type="filled"
      >บันทึกจัดชุดเรียบร้อยแล้ว</vs-button>
    </vs-row>
    <!--------------------------------------------------------------------->
  </vx-card>
</template>

<script>
import Prism from "vue-prism-component";
import Datepicker from "vuejs-datepicker";
import { Validator } from "vee-validate";

import CurrencyValue from "./Components/CurrencyValue";
import shapeFormat from "../../mixins/shapeFormat.js";
import VueBarcode from "vue-barcode";

// import jsPDF from "jspdf";

// import html2canvas from "html2canvas";

const dict = {
  custom: {
    name: {
      required: "ยังไม่ได้ใส่ชื่อลูกค้า"
    }
  }
};

Validator.localize("en", dict);

export default {
  mixins: [shapeFormat],
  props: {
    // list: Array,
    noworderid: String
  },
  data() {
    return {
      detail: [],
      checkAll: false,
      aBranch: {},
      termandcondition: "",
      watermark: true,
      startDate: null,
      endDate: null,

      orderHeaderId: "",
      theLogo: "",
      issuedDate: new Date(),
      displayItems: [],
      branchStock: [],
      itemsInBranch: [],
      // 1. ORDER HEADER
      orderNumber: "",
      orderStatus: "", // 0: darft, 1: saved, 2: waiting for receiving, 3: waiting for returning, 4: waiting for money back, 5: closed
      remark: "",
      remark1: "",

      // 2. CUSTOMER
      customerInfo: "",
      customerName: "",
      customerAddress: "",
      customerPhone: "",
      accountNumber: "",
      accountName: "",
      bankName: "",
      transferContact: "",

      // 3. DELIVERY
      howtoreceive: "มารับเอง", // 1 :มารับเอง, 2: ผ่านเมสเซนเจอร์, 3: ผ่าน EMS / Kerry
      receivingDate: null,
      receivingMethod: "",
      returnDate: null,
      addtocart: "",

      // 4. MONETARY
      rentalPriceTotal: 0,
      bailTotal: 0,
      grandTotal: 0,
      bailDiscount: 0,
      rentalDiscount: 0,
      promotionDiscount: 0,
      rentalDiscountPercent: 0,
      bailDiscountPercent: 0,
      deduction: 0,
      deducingDate: null,

      // 5. LIST
      originalList: [],
      select_order: "OR1903130042",
      order_option: [
        { text: "OR1903130042", value: "OR1903130042" },
        { text: "OR1903130041", value: "OR1903130041" },
        { text: "OR1903130040", value: "OR1903130040" }
      ],
      orders: [],
      shopinfo: {
        name: "",
        phone: "",
        line: "",
        termAndCondition: ""
      }
    };
  },

  async mounted() {
    // this.$vs.loading({ type: "radius" });
    this.theLogo = this.$store.state.defaultStore + "logo.png";
    this.orderHeaderId = this.noworderid;
    // console.log("@@@@@ IN TODO-ORDER-DETAIL >>>> ", this.noworderid);

    this.currentUser = JSON.parse(localStorage.getItem("username"));

    // BEGIN >>>>>>> INITAILZE ITEMS IN BRANCH : params : {thisBranch} >>>>>>>>>

    await this.comeback(this.orderHeaderId);

    this.orders = await this.displayItems;

    var resShop = await this.loadShopInfo();
    if (resShop !== null) {
      this.shopinfo = await resShop.data;
    }
    setTimeout(() => {
      this.$vs.loading.close();
    }, 200);
  },
  methods: {
    getRemoved(ind) {
      return this.detail[ind].flag1;
    },
    async savePrepared(orderid) {
      this.$vs.loading({ type: "radius" });
      // >>>> START TO SAVE HEADER
      var res = null;
      try {
        res = await this.$http.put(
          this.$store.state.apiURL + "/api/orderheader/prepareorder/" + orderid,
          {
            updateBy: this.currentUser
          },
          this.$store.state.auth.apiHeader
        );
      } catch (error) {
        res = null;
      }

      if (res !== null) {
        this.orderHeader = await res.data;
        setTimeout(() => {
          this.$vs.loading.close();
          this.noticeSuccess("บันทึกเตรียมชุดเรียบร้อยแล้ว");
          this.$emit("saveprepare", true);
        }, 500);
      } else {
        setTimeout(() => {
          this.$vs.loading.close();
          this.noticeError("บันทึกไม่สำเร็จ");
          this.$emit("saveprepare", false);
        }, 500);
      }
    },
    checklist(event, indextr, item) {
      this.checkAll = false;
      if (event.target.className == "choose") {
        this.orders[indextr].checked = false;
        event.target.className = "notChoose";
      } else {
        event.target.className = "choose";
        this.orders[indextr].checked = true;
      }

      console.log("CHECK ITEM >>> ", item.checked);
      console.log("ALL ITEMs >>> ", this.orders);
      var chk = true;
      for (let index = 0; index < this.orders.length; index++) {
        chk = chk && this.orders[index].checked;
      }
      if (chk === true) this.checkAll = true;
      console.log("chk >>> ", chk, " this.checkAll >>>>> ", this.checkAll);
    },
    async loadShopInfo() {
      var res = null;
      try {
        res = await this.$http.get(
          this.$store.state.apiURL + "/api/shopinfo",
          this.$store.state.auth.apiHeader
        );
      } catch (err) {
        res = null;
      }

      return res;
    },
    getRedirectLink(orderId) {
      // var ff = "t";
      // if (this.watermark === false) {
      //   ff = "f";
      // } else {
      //   ff = "t";
      // }
      return "/pages/rec-page/" + orderId;
    },
    theLastTotalPrice() {
      var rd = isNaN(Number(this.rentalDiscount))
        ? 0
        : Number(this.rentalDiscount);

      var pd = isNaN(Number(this.promotionDiscount))
        ? 0
        : Number(this.promotionDiscount);

      var total = isNaN(Number(this.rentalPriceTotal))
        ? 0
        : Number(this.rentalPriceTotal);

      console.log("total : ", total, " pd : ", pd, " rd : ", rd);

      return total - (pd + rd);
    },
    theLastBail() {
      var bd = isNaN(Number(this.bailDiscount)) ? 0 : Number(this.bailDiscount);

      var total = isNaN(Number(this.bailTotal)) ? 0 : Number(this.bailTotal);
      return total - bd;
    },
    conditionImage() {
      return this.$store.state.defaultStore + "condition.png";
    },
    alreadycheckedImage() {
      return this.$store.state.defaultStore + "alreadychecked.png";
    },
    rentalImage() {
      return this.$store.state.defaultStore + "rental.png";
    },
    bailImage() {
      return this.$store.state.defaultStore + "bail.png";
    },
    returnImage() {
      return this.$store.state.defaultStore + "return.png";
    },

    backtorental() {
      this.$router.push("/backoffice/rental/" + this.orderHeaderId);
    },
    async update() {
      this.$vs.loading({ type: "radius" });
      // SAVE HEADER

      var ord = {
        // orderHeaderId: "string",
        // orderRef: "string",
        orderNumber: this.orderNumber,
        // orderNumberRef: "string",
        branchId: this.currentBranchId,
        orderStatus: "รอรับชุด",
        remark1: this.remark1,
        // remark2: "string",
        // remark3: "string",
        // remark4: "string",
        customerId: "string",
        customerName: this.customerName,
        customerAddress: this.customerAddress,
        customerPhone: this.customerPhone,
        // accountNumber: "string",
        // accountName: "string",
        // bankName: "string",
        // transferContact: "string",
        receivingMethod: this.receivingMethod,
        pickupDate: this.startDate,
        returnDate: this.endDate,
        rentalPriceTotal: this.rentalPriceTotal,
        bailTotal: this.bailTotal,
        grandTotal: Number(this.grandTotal),
        rentalDiscount: this.rentalDiscount,
        bailDiscount: this.bailDiscount,
        promotionDiscount: this.promotionDiscount,
        // deduction: 0,
        // deductionDate: "2019-07-14T09:40:20.607Z",
        // bailReturningAmount: 0,
        // bailReturningStatus: "string",
        // bailReturningDate: "2019-07-14T09:40:20.607Z",
        description: "",
        state: true,
        // createDateTime: "",
        createBy: this.currentUser,
        // updateDateTime: "",
        updateBy: this.currentUser
      };

      // >>>> START TO SAVE HEADER
      var responseOrder;
      try {
        responseOrder = await this.$http.put(
          this.$store.state.apiURL + "/api/orderheader/" + this.orderHeaderId,
          ord,
          this.$store.state.auth.apiHeader
        );
        if (responseOrder.status === 200) {
          this.orderHeader = await responseOrder.data;
          setTimeout(() => {
            this.$vs.loading.close();
            this.noticeSuccess("ยืนยันข้อมูลเรียบร้อยแล้ว");
            this.orderStatus = "รอรับชุด";
          }, 2000);
        }
      } catch (error) {
        setTimeout(() => {
          this.$vs.loading.close();
          this.noticeError(error);
        }, 2000);
      }

      // >>>> START TO SAVE HEADER
    },
    // >>>>>> เราต้องการ this.itemsInBranch
    async init(branchId) {
      var allInBranch = await this.loadStock(branchId);
      this.branchStock = await allInBranch;

      console.log(" branchStock >>>> ", this.branchStock);

      // LOAD ALL ITEMS
      var allProduct = await this.loadProduct();
      this.originalList = await allProduct;
      this.itemsInStock = await allProduct;

      // console.log(" <<<<< itemsInStock >>>> ", this.itemsInStock);

      // INITAILIZE ITEMS in BRANCH

      for (var i = 0; i < allInBranch.length; i++) {
        var anItem = await allProduct.find(
          x => x.productItemId === allInBranch[i].productItemId
        );

        // console.log("[", i, "]  >>>> anITEM >>>> ", anItem);

        if (anItem !== null && anItem !== undefined) {
          if (anItem.mainImage) {
            anItem.mainImage =
              (await this.$store.state.imageStore) + anItem.mainImage;
          } else {
            anItem.mainImage =
              (await this.$store.state.imageStore) + "defaultImage.png";
          }

          // ADDITIONAL FIELD
          anItem.amountInBranch = allInBranch[i].inStock;
          anItem.reservedQuantity = 1;
          anItem.notAvailable = false;

          this.itemsInBranch.push(anItem);
        }
      }

      console.log("this.itemsInBranch >>>> ", this.itemsInBranch);
    },

    async loadProduct() {
      var response;
      try {
        response = await this.$http.get(
          this.$store.state.apiURL + "/api/productitem",
          this.$store.state.auth.apiHeader
        );
        if (response.status === 200) {
          this.branchStock = await response.data;
        }
      } catch (error) {
        this.noticeError(error);
      }

      return await response.data;
    },
    async loadStock(branchId) {
      var response;
      try {
        response = await this.$http.get(
          this.$store.state.apiURL + "/api/productitem/branch/" + branchId,
          this.$store.state.auth.apiHeader
        );
        if (response.status === 200) {
          this.branchStock = await response.data;
        }
      } catch (error) {
        this.noticeError(error);
        // console.log("error", error);
      }

      return await response.data;
    },
    // >>>>>> เราต้องการ this.displayItems
    async comeback(orderid) {
      var order = await this.loadOrder(orderid);
      var dlist = await this.loadDetail(orderid);
      this.detail = await dlist;

      this.orderNumber = order.orderNumber;

      console.log("@FIRST %%% order >>> ", order);

      this.currentBranchId = await order.branchId;

      await this.init(this.currentBranchId);

      console.log("this.currentBranchId >>> ", this.currentBranchId);

      // orderStatus "saved";

      // customerId "string";
      this.orderNumber = await order.orderNumber;
      this.customerName = await order.customerName;
      this.customerAddress = await order.customerAddress;
      this.customerPhone = await order.customerPhone;
      this.receivingMethod = await order.receivingMethod;
      this.startDate = await order.pickupDate;
      this.endDate = await order.returnDate;
      this.rentalPriceTotal = await order.rentalPriceTotal;
      this.bailTotal = await order.bailTotal;
      this.grandTotal = await order.grandTotal;
      this.rentalDiscount = await order.rentalDiscount;
      this.bailDiscount = await order.bailDiscount;
      this.promotionDiscount = await order.promotionDiscount;
      this.orderStatus = await order.orderStatus;
      this.remark1 = await order.remark1;

      this.aBranch = await this.getBranchShare(this.currentBranchId);

      // LOAD DETAIL
      var list = [];
      for (var d = 0; d < dlist.length; d++) {
        var anItem = await this.itemsInBranch.find(
          x => x.productItemId === dlist[d].productItemId
        );
        anItem.reservedQuantity = dlist[d].reservedQuantity;
        anItem.removed = dlist[d].flag1;
        if (dlist[d].flag1 === "y") {
          anItem.checked = true;
        } else {
          anItem.checked = false;
        }

        await this.displayItems.push(anItem);
        await list.push(anItem);
      }

      console.log("this.displayItems >>> ", this.displayItems);

      return list;
    },

    async loadOrder(orderid) {
      var response;
      try {
        response = await this.$http.get(
          this.$store.state.apiURL + "/api/orderheader/" + orderid,
          this.$store.state.auth.apiHeader
        );
      } catch (error) {
        this.noticeError(error);
      }

      return await response.data;
    },

    async loadDetail(orderid) {
      var response;
      try {
        response = await this.$http.get(
          this.$store.state.apiURL + "/api/orderdetail/" + orderid,
          this.$store.state.auth.apiHeader
        );
      } catch (error) {
        this.noticeError(error);
      }

      return await response.data;
    },

    totalRental: function() {
      var total = 0;
      for (var i = 0; i < this.orders.length; i++) {
        total += this.orders[i].rentalPrice;
      }
      return total;
    },
    // goback
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    setFocus: function() {
      // Note, you need to add a ref="search" attribute to your input.
      // this.$refs.addcart.$el.focus();
    },

    lineTotal: function(rentalPrice, bail) {
      if (!Number.isFinite(rentalPrice)) rentalPrice = 0;
      if (!Number.isFinite(bail)) rentalPrice = 0;
      return rentalPrice + bail;
    },
    removeItem: function(indextr) {
      this.orders[indextr].reservedQuantity = 1;
      this.orders.splice(indextr, 1);
      console.log(this.orders);
    },
    setSelect: function() {
      event.target.setSelectionRange(0, this.addtocart.length);
    }
  },
  created: function() {
    // var rp = this.$route.params.orderno;
    // alert(rp);

    // INITIALIZE DATA

    // 1. ORDER HEADER
    this.orderStatus = 0; // 0: darft, 1: saved, 2: waiting for receiving, 3: waiting for returning, 4: waiting for money back, 5: closed

    // 2. CUSTOMER
    this.customerInfo = "";
    this.customerName = "";
    this.customerAddress = "";
    this.customerPhone = "";

    // 3. DELIVERY
    this.howtoreceive = "มารับเอง"; // 1 :มารับเอง, 2: ผ่านเมสเซนเจอร์, 3: ผ่าน EMS / Kerry
    this.receivingDate = null;
    this.returnDate = null;
    this.addtocart = "";

    // 4. MONETARY

    // this.bailDiscount = 300;
    // this.rentalDiscount = 100;
    this.promotionDiscount = 0;

    this.rentalPriceTotal = 0;
    this.bailTotal = 0;
    this.grandTotal = 0;
  },
  components: {
    Prism,
    Datepicker,
    CurrencyValue,
    VueBarcode
  },

  computed: {},

  watch: {}
};
</script>

<style scoped >
input {
  width: 100%;
}
.theLabel {
  font-weight: bold;
}

.vs-col {
  flex-wrap: wrap;
}
.row-form {
  margin-bottom: 30px;
}
.form-label {
  font-weight: bold;
}

.grand-total {
  font-weight: bold;
  font-family: "SukhumvitSet-Medium";
  font-size: 16px;
}
.grand-total-discount {
  font-family: "SukhumvitSet-Medium";
  font-size: 16px;
}
.table-font {
  font-family: "SukhumvitSet-Medium";
  font-size: 14px;
}

.the-black {
  color: #494949;
}

.the-blue {
  color: #4a90e2;
}
.the-green {
  color: #7ed321;
}
.the-red {
  color: #e95455;
}
.vue-barcode-element {
  width: 200px !important;
  height: 90px;
}
.notChoose {
  width: 50px;
}
</style>
